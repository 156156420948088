const downloadLink = document.querySelector(
	'a[href="/punkte/download"][download]',
);
const isPushPossible =
	'serviceWorker' in navigator &&
	'PushManager' in window &&
	'showNotification' in ServiceWorkerRegistration.prototype &&
	Notification.permission !== 'denied';

const urlBase64ToUint8Array = (base64String: string) => {
	const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
	/* eslint-disable unicorn/prefer-string-replace-all */
	const base64 = `${base64String}${padding}`
		.replace(/-/g, '+')
		.replace(/_/g, '/');
	/* eslint-enable unicorn/prefer-string-replace-all */

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		// eslint-disable-next-line unicorn/prefer-code-point
		outputArray[i] = rawData.charCodeAt(i);
	}

	return outputArray;
};

if (downloadLink && isPushPossible) {
	const appServerKey =
		'BGUreaWb9j3jOPYvsoVTVxfa14fHeFERethylhv6i2xIQEK7FUN-Hw25-K6W-2RZWpDF-ynzHu7_l9qw0zBEy9M';
	let isPushEnabled = false;

	// Create push button
	const pushButton = document.createElement('button');
	pushButton.type = 'button';
	pushButton.ariaPressed = 'false';
	pushButton.textContent = 'Benachrichtigung bei Updates';
	pushButton.id = 'subscription-button';
	pushButton.className = 'button-icon button-bell-on';
	pushButton.addEventListener('click', async () => {
		if (isPushEnabled) {
			await unsubscribe();
		} else {
			void subscribe();
		}
	});
	downloadLink.after(' ', pushButton);

	const changePushButtonState = (state: string) => {
		switch (state) {
			case 'enabled': {
				pushButton.disabled = false;
				pushButton.hidden = false;
				pushButton.ariaPressed = 'true';
				pushButton.textContent = 'Benachrichtigungen abschalten';
				pushButton.classList.remove('button-bell-on');
				pushButton.classList.add('button-bell-off');
				isPushEnabled = true;
				break;
			}

			case 'disabled': {
				pushButton.disabled = false;
				pushButton.hidden = false;
				pushButton.ariaPressed = 'false';
				pushButton.textContent = 'Benachrichtigung bei Updates';
				pushButton.classList.remove('button-bell-off');
				pushButton.classList.add('button-bell-on');
				isPushEnabled = false;
				break;
			}

			case 'computing': {
				pushButton.disabled = true;
				pushButton.hidden = false;
				pushButton.ariaPressed = 'true';
				pushButton.textContent = 'Einen Moment …';
				break;
			}

			default: {
				pushButton.disabled = true;
				pushButton.hidden = true;
				pushButton.ariaPressed = 'false';
				break;
			}
		}
	};

	const checkNotificationPermission = async (): Promise<boolean> => {
		try {
			if (Notification.permission === 'denied') {
				throw new Error('Push messages are blocked.');
			}

			if (Notification.permission === 'granted') {
				return true;
			}

			if (Notification.permission === 'default') {
				const result = await Notification.requestPermission();
				if (result === 'granted') {
					return true;
				}

				throw new Error('Bad permission result');
			}

			throw new Error('Unknown permission');
		} catch (error) {
			console.error(
				'Permission error while subscribing to push notifications',
				error,
			);
			throw error;
		}
	};

	const subscribe = async () => {
		changePushButtonState('computing');

		try {
			await checkNotificationPermission();
			const registration = await navigator.serviceWorker.ready;
			const subscription = await registration.pushManager.subscribe({
				userVisibleOnly: true,
				applicationServerKey: urlBase64ToUint8Array(appServerKey),
			});
			// Create subscription on the server
			const subscribed = await sendSubscriptionToServer(
				subscription,
				'POST',
			);
			if (subscribed) {
				// Update the UI
				changePushButtonState('enabled');
			}
		} catch (error) {
			if (Notification.permission === 'denied') {
				// The user denied the notification permission which
				// means we failed to subscribe and the user will need
				// to manually change the notification permission to
				// subscribe to push messages
				console.warn('Notifications are denied by the user.');
				changePushButtonState('incompatible');
			} else {
				// A problem occurred with the subscription; common reasons
				// include network errors or the user skipped the permission
				console.error(
					'Impossible to subscribe to push notifications',
					error,
				);
				changePushButtonState('disabled');
			}
		}
	};

	const updateSubscription = async () => {
		try {
			const serviceWorkerRegistration =
				await navigator.serviceWorker.ready;
			const subscription =
				await serviceWorkerRegistration.pushManager.getSubscription();

			changePushButtonState('disabled');

			if (!subscription) {
				// We aren't subscribed to push, so set UI to allow the user to enable push
				return;
			}

			// Keep your server in sync with the latest endpoint
			await sendSubscriptionToServer(subscription, 'PUT');
			changePushButtonState('enabled'); // Set your UI to show they have subscribed for push messages
		} catch (error) {
			console.error('Error when updating the subscription', error);
		}
	};

	const unsubscribe = async () => {
		try {
			changePushButtonState('computing');

			// To unsubscribe from push messaging, you need to get the subscription object
			const registration = await navigator.serviceWorker.ready;
			const subscription =
				await registration.pushManager.getSubscription();

			// Check that we have a subscription to unsubscribe
			if (!subscription) {
				// No subscription object, so set the state
				// to allow the user to subscribe to push
				changePushButtonState('disabled');
				return;
			}

			// We have a subscription, unsubscribe
			// Remove push subscription from server
			await sendSubscriptionToServer(subscription, 'DELETE');
			await subscription.unsubscribe();

			changePushButtonState('disabled');
		} catch (error) {
			// We failed to unsubscribe, this can lead to
			// an unusual state, so  it may be best to remove
			// the users data from your data store and
			// inform the user that you have done so
			console.error('Error when unsubscribing the user', error);
			changePushButtonState('disabled');
		}
	};

	const sendSubscriptionToServer = async (
		subscription: PushSubscription,
		method: 'POST' | 'PUT' | 'DELETE',
	) => {
		const key = subscription.getKey('p256dh');
		const token = subscription.getKey('auth');
		const contentEncoding = (PushManager.supportedContentEncodings || [
			'aesgcm',
		])[0];

		await fetch('/push', {
			method,
			body: JSON.stringify({
				endpoint: subscription.endpoint,
				publicKey: key
					? // eslint-disable-next-line no-restricted-globals
						btoa(
							String.fromCharCode.apply(
								null,
								// @ts-expect-error `Uint8Array` can be used as a replacement for `number[]`
								new Uint8Array(key),
							),
						)
					: null,
				authToken: token
					? // eslint-disable-next-line no-restricted-globals
						btoa(
							String.fromCharCode.apply(
								null,
								// @ts-expect-error `Uint8Array` can be used as a replacement for `number[]`
								new Uint8Array(token),
							),
						)
					: null,
				contentEncoding,
			}),
		});
		return subscription;
	};

	// Check the current Notification permission.
	// If its denied, the button should appears as such, until the user changes the permission manually
	if (Notification.permission === 'denied') {
		console.warn('Notifications are denied by the user');
		changePushButtonState('incompatible');
	}

	(async function () {
		try {
			await navigator.serviceWorker.getRegistration();
			await updateSubscription();
		} catch {
			changePushButtonState('incompatible');
		}
	})();
}
