type UrlParameter = [string, string];

const shareLinks: NodeListOf<HTMLAnchorElement> =
	document.querySelectorAll('a.button-share');

const getUrlParameters = (url: string) => {
	const parameters = url
		.split('?')[1]
		.split('&')
		.map((p): UrlParameter => {
			const [parameter, value] = p.split('=');
			return [parameter, decodeURIComponent(value)];
		});

	return new Map(parameters);
};

if (navigator.share) {
	for (const link of shareLinks) {
		// Use Web Share API and replace links with actual buttons
		const button = document.createElement('button');
		button.type = 'button';
		button.innerHTML = link.innerHTML;
		button.classList.add(...link.className.split(' '));

		// Get share data from link
		const shareData = getUrlParameters(link.href);

		// Set actual share event
		button.addEventListener('click', async () => {
			try {
				await navigator.share({
					title: 'Kegelclub Tüddern',
					text: shareData.get('subject'),
					url: shareData.get('body'),
				});
			} catch (error) {
				console.log('Sharing was unsuccessful.', error);
			}
		});

		// Replace link with button
		link.replaceWith(button);
	}
}
